
    import { Component, Vue,Inject, Prop,Watch } from 'vue-property-decorator';
    import Util from '@/lib/util'
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import CreateTenant from './create-tenant.vue'
    import EditTenant from './edit-tenant.vue'
    
    class PageTenantRequest extends PageRequest{
        keyword:string='';
        isActive:boolean=null;
    }
    
    @Component({
        components:{CreateTenant,EditTenant}
    })
    export default class Tenants extends AbpBase{
        edit(){
            this.editModalShow=true;
        }
       
        pagerequest:PageTenantRequest=new PageTenantRequest();
        
        createModalShow:boolean=false;
        editModalShow:boolean=false;
        get list(){
            return this.$store.state.tenant.list;
        };
        get loading(){
            return this.$store.state.tenant.loading;
        }
        create(){
            this.createModalShow=true;
        }
        isActiveChange(val:string){
            if(val==='Actived'){
                this.pagerequest.isActive=true;
            }else if(val==='NoActive'){
                this.pagerequest.isActive=false;
            }else{
                this.pagerequest.isActive=null;
            }
        }
        pageChange(page:number){
            this.$store.commit('tenant/setCurrentPage',page);
            this.getpage();
        }
        pagesizeChange(pagesize:number){
            this.$store.commit('tenant/setPageSize',pagesize);
            this.getpage();
        }
        async getpage(){
            
            this.pagerequest.maxResultCount=this.pageSize;
            this.pagerequest.skipCount=(this.currentPage-1)*this.pageSize;
            
            await this.$store.dispatch({
                type:'tenant/getAll',
                data:this.pagerequest
            })
        }
        get pageSize(){
            return this.$store.state.tenant.pageSize;
        }
        get totalCount(){
            return this.$store.state.tenant.totalCount;
        }
        get currentPage(){
            return this.$store.state.tenant.currentPage;
        }
        columns=[{
            title:this.L('TenancyName'),
            key:'tenancyName'
        },{
            title:this.L('Name'),
            key:'name'
        },{
            title:this.L('IsActive'),
            render:(h:any,params:any)=>{
                return h('span',params.row.isActive?this.L('Yes'):this.L('No'))
            }
        },{
            title:this.L('Actions'),
            key:'Actions',
            width:150,
            render:(h:any,params:any)=>{
                return h('div',[
                    h('Button',{
                        props:{
                            type:'primary',
                            size:'small'
                        },
                        style:{
                            marginRight:'5px'
                        },
                        on:{
                            click:()=>{
                                this.$store.commit('tenant/edit',params.row);
                                this.edit();
                            }
                        }
                    },this.L('Edit')),
                    h('Button',{
                        props:{
                            type:'error',
                            size:'small'
                        },
                        on:{
                            click:async ()=>{
                                this.$Modal.confirm({
                                        title:this.L('Tips'),
                                        content:this.L('DeleteTenantConfirm'),
                                        okText:this.L('Yes'),
                                        cancelText:this.L('No'),
                                        onOk:async()=>{
                                            await this.$store.dispatch({
                                                type:'tenant/delete',
                                                data:params.row
                                            })
                                            await this.getpage();
                                        }
                                })
                            }
                        }
                    },this.L('Delete'))
                ])
            }
        }]
        async created(){
            this.getpage();
        }
    }
